





































































































































































































































































































































































































































































.card label {
  color: black;
  font-size: 14px;
  text-transform: none;
  margin-bottom: 5px;
}
.form-check .form-check-label {
  text-transform: none;
}
.review-quest-div {
  padding-bottom: 10px;
}
.q-report {
  font-size: 12pt;
}
.vertical-scrollable > .row {
  position: absolute;
  top: 120px;
  bottom: 100px;
  left: 180px;
  width: 50%;
  overflow-y: scroll;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.dimScreen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: #999;
}
